export function checkRouteName (url) {
  let urlInfo = {}
  let hasDirectContinue = (url.indexOf("/direct-continue") !== -1) || (url.indexOf("/group-census-direct-continue") !== -1 || (url.indexOf("/pqs-direct-continue") !== -1) || (url.indexOf("/save-cart-direct-continue") !== -1))
  if (hasDirectContinue) {
    let urlPath = url.split('/')
    let id = urlPath[4]
    let tab = urlPath[5]
    urlInfo['urlPath'] = urlPath[3]
    urlInfo['id'] = id
    urlInfo['tab'] = tab
  }
  return urlInfo
}
