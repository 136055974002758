<template>
  <div class="searchEdit-wrap">
    <div class="col-12" v-if="!isHidden && !showDirect">
      <div class="card-block" v-bind:class="{active:active}">
        <ul>
          <li>
            <div class="render-block">
              Zip Code: <span class="brand-color">{{ zip }}</span>
            </div>
          </li>
          <li>
            <div class="render-block">
              Gender: <span class="brand-color">{{ genderOpt }}</span>
            </div>
          </li>
          <li v-if="set_smoker === true">
            <div class="render-block">
              Smoker: <span class="brand-color">{{ smoker }}</span>
            </div>
          </li>
          <li>
            <div class="render-block">
              Tier:
              <span class="brand-color" v-if="tier == 'IO'">Member Only</span>
              <span class="brand-color" v-else-if="tier == 'IS'">Member + Spouse</span>
              <span class="brand-color" v-else-if="tier == 'IC' && isPremierPlan == 'true'">Member + 1 Child</span>
              <span class="brand-color" v-else-if="tier == 'IC' && isPremierPlan == 'false'">Member + Children</span>
              <span class="brand-color" v-else-if="tier == 'IC'">Member + Children</span>
              <span class="brand-color" v-else-if="tier == 'IF'">Family</span>
              <span class="brand-color" v-else></span>
            </div>
          </li>
          <li>
            <div class="render-block">
              DOB: <span class="brand-color">{{ dob }}</span>
            </div>
          </li>
          <li>
            <a v-on:click="isHidden = true" class="edit-search">Edit</a>
          </li>
        </ul>
        <DependentInformation v-if="captureDependent" :tier="tier" @onDependentAdd="relPage"/>
      </div>
    </div>
    <div class="col-12" v-if="isHidden || showDirect">
      <div class="popup_container">
        <!-- <h2>Basic Details</h2> -->
        <div class="row ">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-12 mb-12">
            <div class="card-block">
              <form action="">
                <div class="form-group row align-items-end">
                  <div class="col col-xs-12 mt-2 mb-3">
                    <div :class="{'invalid-field-code-align':$v.zip.$error}">
                      <label for="">Zip Code</label>
                      <input type="number"
                             name=""
                             v-model="zip"
                             id="zip"
                             value=""
                             placeholder="Enter your Zip Code"
                             class="custom-input"
                             @input="$v.zip.$touch(); removeError();"
                             :class="{'form-control is-invalid':$v.zip.$error || formErrors['zip_message']}"
                             oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                             maxlength="5">
                      <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.zip.required && $v.zip.$error">
                        Field is required
                      </div>
                      <div class="form-group__message  ml-3 mt-2" v-if="$v.zip.required && $v.zip.$error">
                        Invalid Zip code
                      </div>
                      <div class="error_message" v-if="formErrorFlag['zip'] == true">{{formErrors['zip_message']}}</div>
                    </div>
                  </div>
                  <div class="col col-xs-12 mt-2 mb-3">
                    <div :class="{'invalid-field-code-align':$v.gender.$error}">
                      <label for="">Gender</label>
                      <div>
                        <b-form-select v-model="gender"
                                       class="custom-input"
                                       @input="$v.gender.$touch(); removeError();"
                                       :class="{'form-control is-invalid':$v.gender.$error || formErrors['gender_message']}"
                                       id="gender"
                                       :options="genderOptions">
                        </b-form-select>
                      </div>
                      <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.gender.required && $v.gender.$error">
                        Field is required
                      </div>
                      <div class="error_message" v-if="formErrorFlag['gender'] == true">{{formErrors['gender_message']}}</div>
                    </div>
                  </div>
                  <div class="col col-xs-12 mt-2 mb-3">
                    <div :class="{'invalid-field-code-align':$v.tier.$error}">
                      <label for="">Choose Tier</label>
                      <b-form-select v-model="tier"
                                     class="custom-input"
                                     @input="$v.tier.$touch(); removeError();"
                                     :class="{'form-control is-invalid':$v.tier.$error || formErrors['tier_message']}"
                                     id="tier"
                                     :options="tierOptions">
                      </b-form-select>
                      <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.tier.required && $v.tier.$error">
                        Field is required
                      </div>
                      <div class="error_message" v-if="formErrorFlag['tier'] == true">{{formErrors['tier_message']}}</div>
                    </div>
                  </div>
                  <div class="col col-xs-12 mt-2 mb-3">
                    <div :class="{'invalid-field-dob-align':$v.dob.$error}">
                      <label for="">DOB</label>
                      <date-picker
                          input-class="custom-input"
                          placeholder="mm/dd/yyyy"
                          type="date"
                          v-model="dob"
                          @input="$v.dob.$touch(); removeError();"
                          :disabled-date="(date) => date >= new Date()"
                          :class="{'invalid':$v.dob.$error || formErrors['dob_message'] }"
                          format="MM/DD/YYYY"
                          value-type="format">
                        <template #input='{ props, events }'>
                          <input
                              v-bind='props'
                              size=10 maxlength=10
                              onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                              v-on='events'>
                        </template>
                      </date-picker>
                      <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.dob.required && $v.dob.$error">
                        Field is required
                      </div>
                      <div class="form-group__message dob-style ml-3 mt-2"  v-if="$v.dob.required && $v.dob.$error">
                        Age must be between 18 and 100 years.
                      </div>
                      <div class="error_message" v-if="formErrorFlag['dob'] == true">{{formErrors['dob_message']}}</div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 mt-2 mb-3 col-12">
                    <div class="editAction-btn">
                      <button class="dark-univ-btn" @click.prevent="for_back()" v-if="tempId">Back</button>
                      <button type="button" class="univ-btn" @click="submitAPI">Submit</button>
                    </div>
                  </div>
                </div>
                <DependentInformation v-if="captureDependent" :tier="tier"  :isSubmitHidden="isHidden"/>
                <br>
                <!-- <div class="form-group row">
                  <div class="col-md-3">
                    <button class="dark-univ-btn" v-on:click="isHidden = false">Back</button>
                  </div>
                  <div class="col-md-3">
                    <button type="button" class="univ-btn" @click="submitAPI">Submit</button>
                  </div>
                </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Multiselect from 'vue-multiselect'
import axios from 'axios';
import {required} from "vuelidate/lib/validators";
import {checkValidZip, isOver18} from "../utils/validators";
import DependentInformation from './dependent/DependentInformation.vue';
export default {
  name: 'SearchEdit',
  components: {
    DependentInformation,
  },
  props:{
    showDirect:Boolean,
    captureDependent:Boolean,
  },
  data: function () {
    return {
      tempId: '',
      usertype: '',
      category: '',
      carrier: [],
      catList: {},
      catArray: [],
      dob: '',
      tier: '',
      state: '',
      zip: '',
      effectiveDate: '',
      gender: '',
      annualIncome: '',
      smoker: '',
      is_smoker: '',
      genderOpt: '',
      visible: false,
      eprocess: '',
      agent_id: '',
      group_id: '',
      active: false,
      userId: '',
      enrollmentType: '',
      set_smoker: false,
      formErrorFlag: [],
      formErrors: [],
      isHidden: false,
      format:'MM/dd/yyyy',
      value: null,
      isPremierPlan: true,
      // options:''
      genderOptions: [
        { value: '', text: 'Choose Gender', disabled: true},
        { value: '0', text: 'Male' },
        { value: '1', text: 'Female'}
      ],
      tierOptions: [
        { value: '', text: 'Choose Tier', disabled: true},
        { value: 'IO', text: 'Member Only'},
        { value: 'IS', text: 'Member + Spouse'},
        { value: 'IC', text: 'Member + Children'},
        { value: 'IF', text: 'Family'}
      ]
    }
  },
  validations: {
    zip: {
      required,
      checkValidZip
    },
    tier: {
      required
    },
    dob: {
      required,
      isOver18
    },
    gender: {
      required
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.agent_id = window.localStorage.getItem('AGENT_ID');
    app.group_id = window.localStorage.getItem('group_id');
    app.platformId = window.localStorage.getItem('portal_id');
    app.enrollType = window.localStorage.getItem('enroll_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.open_type = window.localStorage.getItem('open_type');
    app.getType();
    app.checkEprocess();
    app.setLocationZipCode();
    app.getFilters();
    app.getOptions();
  },
  computed: {
    locationZipCode () {
      return this.$store.getters.getZipCode
    }
  },
  methods: {
    setLocationZipCode () {
      // function is responsible to set zip code from location permission if allowed
      let app = this
      if (!app.tempId) {
        app.zip = app.locationZipCode
      }
    },
    updateFilters:function(){
      let app=this
      let filters={
        dob: app.dob,
        state: app.state,
        tier:app.tier,
        gender:app.gender=='0'?'male':'female',
      }
      console.log('Filter are mine', filters)
      app.$emit('updateFilter',filters)
    },
    getType: function () {
      let app = this;
      if(this.tempId){
        axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'type',
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.enrollmentType = response.data.data.result;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    checkEprocess: function () {
      let app = this;
      if(app.tempId){
        axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'eprocess',
          }
        })
            .then(function (response) {
              console.log(response.data.data.result);
              if (response.data.status == 'success') {
                app.eprocess = response.data.data.result;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    for_back(){
      let app = this;
      app.isHidden = false;
      if(app.$route.name == 'plan' && this.tempId == null){
        app.$emit('backFunction');
      }
    },
    getFilters: function () {
      let app = this;
      if(app.tempId){
        axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'filter_info'
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.dob = response.data.data.result.dob;
                app.zip = response.data.data.result.zip ? response.data.data.result.zip : app.locationZipCode;
                app.state = response.data.data.result.state;
                window.localStorage.setItem('state',app.state)
                app.tier = response.data.data.result.tier;
                app.gender = response.data.data.result.gender;
                if (app.gender == '0') {
                  app.genderOpt = "M";
                } else {
                  app.genderOpt = "F";
                }
                app.is_smoker = response.data.data.result.is_smoker;
                if (response.data.data.result.is_smoker == 1) {
                  app.smoker = "Yes";
                } else {
                  app.smoker = "No";
                }
                if (response.data.data.result.is_smoker == null || response.data.data.result.is_smoker == '' || response.data.data.result.is_smoker == undefined) {
                  app.set_smoker = false;
                } else {
                  app.set_smoker = true;
                }
                if (app.enrollmentType == 'existing-user') {
                  app.userId = response.data.data.result.user_id;
                }
                app.updateFilters();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    submitAPI: function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('***** Inside Save Data Invalid*****')
        return false
      } else {
        // Submit API and
        // Navigate to plans page
        let app = this;
        app.annualIncome = window.localStorage.getItem('annualPrudentialSalary')
        let params = {
          zip: app.zip,
          dob: app.dob,
          is_smoker: app.is_smoker,
          gender: app.gender,
          tier: app.tier,
          eprocess: app.eprocess,
          agent_id: app.agent_id,
          group_id: app.group_id,
          platform_group_id: app.platformId,
          annual_incode: app.annualIncome,
          user_id: app.userId,
          open_type:app.open_type,
        };
        window.localStorage.setItem("selectedTier", app.tier);
        //discard null values
        if (app.tempId == null) {
          axios.post(process.env.VUE_APP_API_BASE + '/add-filter-for-new-user', {
            zip: app.zip,
            dob: app.dob,
            // is_smoker: app.is_smoker,
            gender: app.gender,
            tier: app.tier,
            eprocess: app.eprocess,
            agent_id: app.agent_id,
            group_id: app.group_id,
            platform_group_id: app.platformId,
            type: app.enrollType,
            open_type:app.open_type,
          })
              .then(function (response) {
                if (response.data.status == 'success') {
                  window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
                  window.localStorage.setItem('show-quotation', true);
                  window.localStorage.setItem('isFilterDataSubmitted', true);
                  app.formErrorFlag = [];
                  app.formErrors = [];
                  app.isHidden = false;
                  app.showDirect = false;
                  app.$bus.$emit('getMenuList')
                  app.emitfunction();
                  app.updateFilters();
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag[item.target_element] = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        } else {
          for (const key of Object.keys(params)) {
            if (params[key] === null || params[key] === '' || params[key] === undefined) {
              delete params[key];
            }
          }
          axios.put(process.env.VUE_APP_API_BASE + '/change-filter-info/' + app.tempId, params)
              .then(function (response) {
                if (response.data.status == 'success') {
                  app.isHidden = false
                  app.getFilters();
                  app.$bus.$emit('getMenuList')
                  app.$emit('getPlanList')
                  if (app.tempId != null) {
                    app.$root.$emit("cartData", app.tempId);
                    app.$emit('getPlanList')
                  }
                  app.relPage();
                  app.updateFilters();
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag[item.target_element] = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        }
      }
    },
    emitfunction(){
      let app =this;
      app.$emit('callFunctions');
      app.isHidden = true;
      app.showDirect=true;
    },
    showPop() {
      this.visible = true;
    },
    closePop() {
      this.visible = false;
    },
    relPage: function () {
      let app = this;
      if (app.$route.name == 'plans') {
        app.$emit('callFunctionsfromplans');
      }
      else if (app.$route.name == 'plan') {
        app.$emit('callFunctionsupdate');
      }
    },
    checkValue: function (str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    eventDob: function () {
      let app = this;
      var input = app.dob;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
      var values = input.split('/').map(function (v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = app.checkValue(values[0], 12);
      if (values[1]) values[1] = app.checkValue(values[1], 31);
      var output = values.map(function (v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      app.dob = output.join('').substr(0, 10);
    },
    filterCat: function (cat, event) {
      if (event.target.checked) {
        console.log(cat);
        this.category.push(cat);
      } else {
        var index = this.category.indexOf(cat);
        this.category.splice(index, 1);
      }
      console.log(this.category);
    },
    getOptions: function ()
    {
      this.catList.forEach(function (item)
      {
        console.log('here is foreach items',item)
      });
    },
    removeError () {
      this.formErrors = []
    },
    checkPremierPlan () {
      let app = this
      if (app.isPremierPlan == 'true') {
        app.tierOptions = [
          { value: '', text: 'Choose Tier', disabled: true},
          { value: 'IO', text: 'Member Only'},
          { value: 'IS', text: 'Member + Spouse'},
          { value: 'IC', text: 'Member + 1 Child'},
          { value: 'IF', text: 'Family'}
        ]
      } else {
        app.tierOptions = [
          { value: '', text: 'Choose Tier', disabled: true},
          { value: 'IO', text: 'Member Only'},
          { value: 'IS', text: 'Member + Spouse'},
          { value: 'IC', text: 'Member + Children'},
          { value: 'IF', text: 'Family'}
        ]
      }
    }
  },
  listener: {
    checkPremierTier() {
      let app = this
      app.isPremierPlan = window.localStorage.getItem('isPremierPlan')
      app.checkPremierPlan()
    },
    updateFilterForPrudentialPlan () {
      let app = this
      app.submitAPI();
    },
    renderGroupAndPortalInfo () {
      let app = this
      app.platformId = window.localStorage.getItem('portal_id');
      app.group_id = window.localStorage.getItem('group_id');
    }
  }
}
</script>
<style>
.invalid-field-code-align {
  position: relative;
  top: 26px;
}
.invalid-field-dob-align {
  position: relative;
  top: 28px;
}
.dob-style {
  font-size: 11px;
}
.editAction-btn {
  margin-top: 18px;
}
@media only screen and (max-width: 768px) {
  .invalid-field-dob-align {
    margin-bottom: 6px;
    top: 16px;
  }
}
</style>
